import logo from "./logo.svg"
import "./App.css"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.js"
import { useState } from "react"
import Swal from "sweetalert2"

function App() {
  const [result, setResult] = useState("")
  const [input, setInput] = useState("")
  const [sesi, setSesi] = useState(1)
  function ucapan(data) {
    const link = data.replaceAll(" ", "-")
    setResult(`*Kepada :*
Yth. Bapak/Ibu/Saudara/i
${data}

_*Bismillahirrahmanirrahim*_
_*Assalamu'alaikum Wr Wb*_


Tanpa mengurangi rasa hormat, perkenankan kami mengundang  Bapak/Ibu/Saudara/i untuk menghadiri acara pernikahan : 

*Mia Amelia*
       *&*
*Tomi Sugiarto*

Untuk info selengkapnya dari acara dapat diakses pada link berikut :
https://wedding.tto.my.id/${sesi}/${link}

Besar Harapan kami Bapak/Ibu/Saudara/i berkenan untuk hadir dalam acara pernikahan kami. 

Mohon maaf perihal undangan hanya kami bagikan melalui pesan ini.

Terimakasih.


*Wassalamualaikum Wr.Wb*.`)
  }
  return (
    <div className="App">
      <div className="d-flex justify-content-center">
        <div className="mt-4" style={{ maxWidth: "15cm" }}>
          <div className="">Kepada</div>
          <div>
            <input
              type="text"
              className="form-control shadow-none mt-2"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <div className="d-flex mt-2">
              <div
                onClick={() => setSesi(1)}
                className={
                  sesi === 1
                    ? "w-100 border py-2 bg-secondary text-white"
                    : "w-100 border py-2"
                }
              >
                1
              </div>
              <div
                onClick={() => setSesi(2)}
                className={
                  sesi === 2
                    ? "w-100 border py-2 bg-secondary text-white"
                    : "w-100 border py-2"
                }
              >
                2
              </div>
            </div>
            <div
              className="bg-success text-white py-1 mt-3"
              style={{ borderRadius: 15 }}
              onClick={() => ucapan(input)}
            >
              generate
            </div>
            {result ? (
              <div
                className="bg-primary text-white py-1 mt-3"
                style={{ borderRadius: 15 }}
                onClick={() => {
                  navigator.clipboard.writeText(result)
                  Swal.fire({
                    text: "Copied!",
                    showConfirmButton: false,
                    timer: 500,
                  })
                  setInput("")
                  setResult("")
                }}
              >
                Copy
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
